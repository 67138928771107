<template>
  <v-card class="mt-4">
    <v-card-title>
      <v-row>
        <v-col>Withdrawal History</v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="items.length > 0">
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>{{ item.amount }}</td>
              <td>
                <v-chip
                  text-color="white"
                  :class="statusClass(item.transaction_status)"
                >
                  {{ item.transaction_status | withdrawalStatusLabel }}
                </v-chip>
              </td>
              <td>{{ item.account_balance_after }}</td>
              <td>{{ item.phone_number }}</td>
              <td>{{ item.created_at | formatDate }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    DataTablePagination,
  },
  filters: {
    withdrawalStatusLabel(status) {
      const statusLabels = { 0: 'Pending', 1: 'Approved', 2: 'Rejected' }

      return statusLabels[status] || 'Unknown'
    },
    formatDate(value) {
      if (!value) return ''
      const date = new Date(value)

      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    },
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    resellerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'transaction_status' },
        { text: 'Account Balance', value: 'account_balance_after', sortable: false },
        { text: 'Phone Number', value: 'phone_number', sortable: false },
        { text: 'Date', value: 'created_at' },
      ],
      loading: false,
      sortBy: ['created_at'],
      sortDesc: [true],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
    }
  },
  computed: {
    refreshTable() {
      return `${this.sortBy}|${this.sortDesc}|${this.pagination.page}|${this.pagination.itemsPerPage}|${this.resellerId}`
    },
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
  },
  watch: {
    refreshTable() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
    this.$on('refresh-table', this.fetchData)
  },
  beforeDestroy() {
    this.$off('refresh-table')
  },
  methods: {
    fetchData() {
      this.loading = true
      let requestUrl = this.url

      // Append resellerId to URL if it is not null
      if (this.resellerId) {
        requestUrl = `${requestUrl}/${this.resellerId}`
      }

      const params = {
        sortBy: this.sortBy[0],
        sortOrder: this.sortOrder,
        page: this.pagination.page,
        perPage: this.pagination.itemsPerPage,
      }

      axios
        .get(requestUrl, { params })
        .then(response => {
          this.items = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
        })
        .catch(error => {
          this.$notification.error(error.response?.data?.message || 'An error occurred')
        })
        .finally(() => {
          this.loading = false
        })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchData()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.fetchData()
    },
    statusClass(status) {
      return {
        pending: status === 0,
        approved: status === 1,
        rejected: status === 2,
      }
    },
  },
}
</script>

<style scoped>
.v-chip.rejected {
  background: #f44336;
}
.v-chip.approved {
  background: #4caf50;
}
.v-chip.pending {
  background: #ffa726;
}
</style>
