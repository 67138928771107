<template>
  <div class="wallet">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col
            class="col-md-10"
            style="display: flex;
    align-items: center;"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiWallet }}
            </v-icon>
            Wallet Balance: <strong class="ml-2">Ksh {{ balance }}</strong>
          </v-col>
          <v-col class="col-md-2">
            <refresh-button
              :loading="isWalletBalanceRefreshing"
              @click="getBalance()"
            />
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <withdrawal-form
      :phone-number="form.phone_number"
      @balanceUpdated="getBalance"
    />
    <v-card class="mt-4">
      <withdrawal-history-table url="reseller/withdrawal-history" />
    </v-card>
  </div>
</template>

<script>
import { mdiWallet } from '@mdi/js'
import axios from 'axios'
import Form from 'vform'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import validationRules from '@/mixins/validationRules'
import WithdrawalHistoryTable from '@/components/tables/WithdrawalHistoryTable.vue'
import WithdrawalForm from '@/components/partials/WithdrawalForm.vue'

export default {
  components: {
    RefreshButton,
    WithdrawalHistoryTable,
    WithdrawalForm,
  },
  mixins: [validationRules],
  data() {
    return {
      balance: '0.00',
      isLoadingPhoneNumber: false,
      isWalletBalanceRefreshing: false,
      isFormValid: true,
      icons: {
        mdiWallet,
      },
      form: new Form({
        phone_number: '',
        amount: '',
      }),
      withdrawal_history: [],
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    statusChipColor() {
      return {
        0: 'grey',
        1: 'green',
        2: 'red',
      }
    },
  },
  mounted() {
    this.getBalance()
    this.getPhoneNumberInfo()
  },
  methods: {
    withdraw() {
      if (this.isFormValid) {
        this.form.post('reseller/withdraw')
          .then(response => {
            this.$notification.success(response.data.message)
            setTimeout(() => {
              this.getBalance()
            }, 2000)
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    getBalance() {
      this.isWalletBalanceRefreshing = true
      axios.get('reseller/balance')
        .then(response => {
          this.balance = response.data.balance
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
        .finally(() => {
          this.isWalletBalanceRefreshing = false
        })
    },
    getPhoneNumberInfo() {
      this.isLoadingPhoneNumber = true
      axios
        .get('auth/admin/profile')
        .then(response => {
          this.form.phone_number = response.data.phone
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        }).finally(() => {
          this.isLoadingPhoneNumber = false
        })
    },
  },

}
</script>
