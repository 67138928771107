<template>
  <v-card class="mt-4">
    <v-card-title>
      <v-row>
        <v-col>
          Withdraw
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <p>
        Withdraw from
        <span v-if="resellerName"><b>{{ resellerName }}'s </b></span><span v-else> your </span> wallet.
        <span v-if="walletBalance"> Current balance: <b>Ksh {{ walletBalance }} </b></span>
      </p>
      <v-form
        v-model="isFormValid"
        @submit.prevent="withdraw"
      >
        <v-text-field
          v-model="form.amount"
          label="Amount"
          type="number"
          placeholder="Ksh 100"
          :rules="numberRules"
          outlined
        />
        <v-text-field
          v-model="form.phone_number"
          label="Phone Number"
          type="number"
          placeholder="07xxxxxxxx"
          :rules="phoneRules"
          :loading="isLoadingPhoneNumber"
          outlined
        />
        <v-btn
          color="primary"
          class="mt-4"
          type="submit"
          :loading="form.busy"
          block
        >
          Withdraw
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Form from 'vform'
import validationRules from '@/mixins/validationRules'

export default {
  name: 'WithdrawalForm',
  mixins: [validationRules],
  props: {
    phoneNumber: {
      type: [String, Number],
      default: null,
    },
    resellerId: {
      type: String,
      default: null,
    },
    resellerName: {
      type: String,
      default: null,
    },
    walletBalance: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      isLoadingPhoneNumber: false,
      isFormValid: true,
      form: new Form({
        phone_number: this.phoneNumber,
        amount: '',
      }),
    }
  },
  watch: {
    phoneNumber() {
      this.form.phone_number = this.phoneNumber
    },
  },
  methods: {
    withdraw() {
      if (this.isFormValid) {
        // Determine the endpoint dynamically based on the presence of resellerId
        const endpoint = this.resellerId
          ? `reseller-withdraw/${this.resellerId}`
          : 'reseller/withdraw'

        this.form.post(endpoint)
          .then(response => {
            this.$notification.success(response.data.message)
            this.$emit('balanceUpdated') // Notify parent to refresh balance
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
